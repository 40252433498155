import React from "react";
import style from "./un.module.css";

export default function UnPage(){
    return(
        <>
        <div className={style.page}>
            <div className={style.container}>
                <p>Em breve</p>
                <strong>Coda<span>Web</span></strong>
            </div>
        </div>
        </>
    );
}