import { BrowserRouter, Route, Routes } from "react-router-dom";
//import Home from "./pages/home"; <Route path="/" element={<Home />}>
import Contato from "./pages/contato";
import Galeria from "./pages/galeria";
import UnPage from "unlaunched";

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<UnPage />}></Route>
        <Route path="/contato" element={<Contato />}></Route>
        <Route path="/galeria" element={<Galeria />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
