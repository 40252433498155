import Forms from "components/forms";
import React from "react";

export default function Contato() {
  return (
    <>
      <Forms />
    </>
  );
}
