import React from "react";
import style from "./style.module.css";

export default function Forms(){
    return(
        <>
          <div className={style.forms_component}>
            <div className={style.form_area}>
                <form className={style.formulario} name="contact" method="POST" data-netlify="true">
                    <label className={style.label}>
                        Seu nome:
                        <input className={style.input}></input>
                    </label>
                    <label className={style.label}>
                        Seu e-mail:
                        <input className={style.input}></input>
                    </label>
                    <label className={style.label}>
                        Sua mensagem:
                        <textarea className={style.textarea}></textarea>
                    </label>
                    <input className={style.btn_env} type="submit" value="Enviar"></input>
                </form>
            </div>
          </div>
        </>
    );
}